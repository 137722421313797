<template>
  <div class="phone-recharge-two">
    <div class="content">
      <div class="coupon">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_two_coupon.png">
        <div class="coupon-inner">
          <img class="provider" src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_two_provider.png" alt="">
          <img class="coupon-img" src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_two_amount.png" alt="">
          <div class="amount">
            <p>有效期剩：</p>
            <van-count-down millisecond :time="effectiveTime" format="HH:mm:ss:SS"/>
          </div>
          <van-button class="coupon-button" @click="handleGetCoupon">立即领取</van-button>
          <p class="count">仅剩<span>5</span>份 先到先得</p>
        </div>
      </div>
      <div class="fixed-button">
        <img class="rules" src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_rule1.png" @click="handleJumpApp('rule')">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_two_service.png"
             @click="handleJumpApp('service')">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_report.png" @click="handleJumpApp('report')">
      </div>
      <div class="coupon-introduction">
        <p>以下权益专属优惠，仅在支付会员费成为鲸选省钱卡会员后方可享有，均在鲸选师APP内使用：</p>
        <p v-if="showMore">
          <br/>
          <br/>
          1、费用说明：支付会员费后即可成为鲸选省钱卡会员，一次办理，终身免年费，我们将不再收取任何费用。
          <br/>
          <br/>
          2、参与活动的新用户成功办理鲸选省钱卡会员后，将获得总价值100元的话费券，本话费券用于三网客户充值服务，可在全国范围（港澳台除外）使用，更多规则详见鲸选师APP内活动规则；
          <br/>
          <br/>
          3、参与活动的新用户成功办理鲸选省钱卡会员后，将获得总价值100元的话费返利卡，共计20张价值5元的话费券，每次仅限使用一张话费券，更多使用规则详见鲸选师APP内活动规则；
          <br/>
          <br/>
          4、话费券使用方法：登录鲸选师APP--点击【我的页面】中的话费券--点击话费券跳转至话费充值平台进行充值即可；
          <br/>
          <br/>
          5、返利金在用户确认收货后次月25号，将返还到用户的APP账号余额，可随时申请提现至微信钱包，账号余额查询方式：鲸选师APP--我的--我的钱包。
          <br/>
          <br/>
          6、话费充值因涉及第三方通信运营商，以及网络系统的稳定性等原因，充值话费到账时间可能存在延迟，尽请谅解，您可咨询客服或关注“鲸选师”公众号以及微信小程序，我们将及时回复处理。
          服务时间（工作日）：9:00-24:00。
          <br/>
          <br/>
          7、如何退费：①可登录鲸选师APP→我的→联系客服→申请退款（联系在线客服），注册会员后1个月内未下载APP领券话费券的会员用户，随时可申请退款，发起申请后，我们会在3个工作日内完成退款。登录APP且领取话费券的会员，无法退款，购买会员前请您再次确认。②当您购买我们的鲸选省钱卡会员后，若您选择申请退款，退款成功后，您的话费券领取资格将失效，请知悉。
          <br/>
          <br/>
          8、在法律允许的范围内，根据活动的进展情况，鲸选师可能对活动的规则/条款作出适当修改/调整。
          <br/>
          <br/>
        </p>
      </div>
      <div class="spread-down" v-if="!showMore">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_two_down.png" @click="handleSpread">
      </div>
    </div>
    <van-dialog v-model="dialogVisible" show-cancel-button :closeOnClickOverlay="true" @confirm="submitPhone"
                @cancel="handleDialogCancel">
      <div class="dialog-phone">
        <p>请输入您的手机号</p>
        <van-field class="phone-input" type="number" pattern="\d*" v-model="phone"></van-field>
      </div>
    </van-dialog>
    <van-dialog v-model="rulesVisible" :showConfirmButton="false" :closeOnClickOverlay="true"
                style="background-color: transparent;">
      <div v-if="dialogType == 'rule'" class="wrapper">
        <p>活动规则</p>
        <div class="content">
          <p>
            以下权益专属优惠，仅在支付会员费成为鲸选省钱卡会员后方可享有，均在鲸选师APP内使用：
            <br/>
            <br/>
            1、费用说明：支付会员费后即可成为鲸选省钱卡会员，一次办理，终身免年费，我们将不再收取任何费用。
            <br/>
            <br/>
            2、参与活动的新用户成功办理鲸选省钱卡会员后，将获得总价值100元的话费券，本话费券用于三网客户充值服务，可在全国范围（港澳台除外）使用，更多规则详见鲸选师APP内活动规则；
            <br/>
            <br/>
            3、参与活动的新用户成功办理鲸选省钱卡会员后，将获得总价值100元的话费返利卡，共计20张价值5元的话费返利卡，每次仅限使用一张话费返利卡，返利卡使用规则详见鲸选师APP内活动规则；
            <br/>
            <br/>
            4、话费券使用方法：登录鲸选师APP--点击【我的页面】中的话费券--点击话费券跳转至话费充值平台进行充值即可；
            <br/>
            <br/>
            5、返利金在用户确认收货后次月25号，将返还到用户的APP账号余额，可随时申请提现至微信钱包，账号余额查询方式：鲸选师APP--我的--我的钱包。
            <br/>
            <br/>
            6、话费充值因涉及第三方通信运营商，以及网络系统的稳定性等原因，充值话费到账时间可能存在延迟，尽请谅解，您可咨询客服或关注“鲸选师”公众号以及微信小程序，我们将及时回复处理。
            服务时间（工作日）：9:00-24:00。
            <br/>
            <br/>
            7、如何退费：①可登录鲸选师APP→我的→联系客服→申请退款（联系在线客服），注册会员后1个月内未下载APP领券话费券的会员用户，随时可申请退款，发起申请后，我们会在3个工作日内完成退款。登录APP且领取话费券的会员，无法退款，购买会员前请您再次确认。②当您购买我们的鲸选省钱卡会员后，若您选择申请退款，退款成功后，您的话费券领取资格将失效，请知悉。
            <br/>
            <br/>
            8、在法律允许的范围内，根据活动的进展情况，鲸选师可能对活动的规则/条款作出适当修改/调整。
          </p>
        </div>
        <van-button @click="handleCloseOverlay">立即办理</van-button>
        <!--        <div class="close">-->
        <!--          <img src="https://cdn.cailu88.com/jingxuanshi/close%402x.png" @click="handleCloseOverlay"/>-->
        <!--        </div>-->
      </div>
      <div v-else-if="dialogType == 'service'" class="wrapper">
        <div class="wx-qrcode">
          <img src="https://cdn.cailu88.com/jingxuanshi/kf_official.png"/>
        </div>
        <div class="service">
          <p class="service-time">客服服务时间：9:00-24:00</p>
          <p class="service-info">打开微信，扫描二维码添加客服</p>
        </div>
        <van-button @click="handleSaveWX">保存二维码</van-button>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import Vue from 'vue'
import { Field, Button, Dialog, CountDown, Toast } from 'vant'
import baseUrl from '@/utils/baseUrl'

const api = require('@/utils/api.js').api

Vue.use(Field)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(CountDown)

export default {
  name: 'phoneRechargeTwo',
  data () {
    return {
      //展示更多说明
      showMore: false,
      // 弹窗
      dialogVisible: false,
      rulesVisible: false,
      // 手机号
      phone: '',
      // 弹窗类型
      dialogType: '',
      // 倒计时
      effectiveTime: 15 * 60 * 1000,
      pi: '',
      attach: ''
    }
  },
  created () {
    this.pi = this.$route.query.pi
    this.attach = this.$route.query.bc_tag ? this.$route.query.bc_tag : ''
    localStorage.setItem('attach', this.attach)
  },
  mounted () {
    // 友盟统计
    const scriptSta = document.createElement('script')
    scriptSta.src = 'https://v1.cnzz.com/z_stat.php?id=1279885565&web_id=1279885565'
    scriptSta.language = 'JavaScript'
    document.body.appendChild(scriptSta)
    this.handleGetBaseInfo()
  },
  methods: {
    /*
    跳转jxsAPP内部事件
     */
    handleJumpApp (target) {
      if (target == 'report') {
        this.$router.push({
          name: 'putReport',
          query: {
            pi: this.pi,
            template: 2
          }
        })
      }else {
        this.dialogType = target
        this.rulesVisible = true
      }
    },

    /*
    展开更多说明
     */
    handleSpread () {
      this.showMore = true
    },

    /*
    立即领取话费券
     */
    handleGetCoupon () {
      this.dialogVisible = true
    },
    /*
    获取投放基本信息
     */
    handleGetBaseInfo () {
      this.$axios({
        url: api.baseInfo,
        method: 'get',
        params: {
          phone: this.phone,
          pushTemplateId: this.pi,
          bizType: 1,
          template: 2
        }
      }).then(res => {
      }).catch(err => {
        console.info(err)
      })
    },

    /*
    提交手机号
     */
    submitPhone () {
      if (this.phone.split('').length != 11) {
        Toast.fail('请输入正确的手机号')
        return
      }
      this.$axios({
        url: api.privilegeCheck,
        method: 'get',
        params: {
          phone: this.phone,
          pushTemplateId: this.pi,
          redirectUrl: `${baseUrl.pageUrl}/jingxuanvip/check`,
          bizType: 1,
          template: 2
        }
      }).then(res => {
        // recharged true 使用过特权, false 未使用特权
        this.$router.push({
          name: 'orderCheck',
          query: {
            phone: this.phone,
            recharged: res.data.data.recharged,
            pi: this.pi,
            wxRedirectUrl: res.data.data.wxRedirectUrl
          },
        })
      }).catch(err => {
        console.info(err)
      })
    },

    /*
    弹窗取消
     */
    handleDialogCancel () {
      this.phone = ''
    },

    /*
    保存wx二维码
    */
    handleSaveWX () {
      let picUrl = 'https://cdn.cailu88.com/jingxuanshi/kf_official.png'
      let link = document.createElement('a')
      link.download = name || 'pic'
      link.href = picUrl
      link.click()
      this.rulesVisible = false
    },

    handleCloseOverlay(){
      this.rulesVisible = false
      setTimeout(() => {
        this.dialogVisible = true
      })
    },

  }
}
</script>
<style lang="less" scoped>
.phone-recharge-two {
  min-height: 100vh;
  background: #B3EAFF;

  .content {
    .coupon {
      position: relative;
      text-align: center;

      & > img {
        width: 350px;
      }

      .coupon-inner {
        position: absolute;
        top: 24px;
        left: 2px;

        .provider {
          width: 304px;
        }

        .coupon-img {
          margin-top: -6px;
          width: 333px;
        }

        .amount {
          margin: -10px auto 0;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-size: 18px;
            font-weight: 400;
            color: #2D7895;
            line-height: 25px;
          }

          .van-count-down {
            width: 90px;
            color: #2D7895;
          }
        }

        .count {
          margin-top: 11px;
          font-size: 20px;
          font-weight: 400;
          color: #2D7895;
          line-height: 28px;

          & > span {
            margin: 0 5px;
            color: #E44A59;
          }
        }

        .coupon-button {
          margin-top: 20px;
          width: 306px;
          height: 55px;
          background: linear-gradient(180deg, #FF8162 0%, #FF5D41 100%);
          box-shadow: 0px 2px 8px 0px #FE8D62;
          border-radius: 28px;
          font-size: 20px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 28px;
          border: none;
        }
      }
    }

    .fixed-button {
      position: fixed;
      right: 0;
      top: 87px;
      display: flex;
      flex-direction: column;

      .rules {
        position: absolute;
        top: -48px;
        right: 0;
        width: 54px;
      }

      img {
        width: 34px;
        margin-bottom: 3px;
      }
    }

    .coupon-introduction {
      margin: 0 auto;
      width: 306px;

      p {
        font-size: 13px;
        font-weight: 400;
        color: #2D7895;
        line-height: 20px
      }
    }

    .spread-down {
      margin-top: 10px;
      width: 100%;
      text-align: center;

      & > img {
        width: 24px;
        height: 24px;
      }
    }
  }

  .dialog-phone {
    padding: 24px;
    text-align: center;

    p {
      font-size: 20px;
      font-weight: bold;
      color: #FF5E42;
      line-height: 28px;
    }

    .van-field {
      margin: 20px auto 0;
      width: 250px;
      background: #F4F4F4;
      border-radius: 28px;
    }
  }
  .wrapper {
    position: relative;
    width: 320px;
    background: #FFFFFF;
    border-radius: 16px;
    text-align: center;

    & > p {
      display: inline-block;
      margin-top: 24px;
      margin-bottom: 12px;
      font-size: 20px;
      font-weight: bold;
      color: #7F694E;
      line-height: 28px;
    }

    .content {
      margin: 0 auto;
      width: 272px;
      height: 313px;
      font-size: 15px;
      font-weight: 400;
      overflow-y: scroll;
      color: #333333;
      line-height: 25px;

      p {
        text-align: left;
      }
    }

    .van-button {
      margin-top: 10px;
      margin-bottom: 15px;
      width: 280px;
      height: 50px;
      background: linear-gradient(90deg, #FFE687 0%, #FFC667 100%);
      border-radius: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #7F694E;
      line-height: 25px;
    }

    .wx-qrcode {
      display: inline-block;
      margin-top: 35px;
      & > img{
        width: 198px;
        height: 198px;
      }
    }

    .service {
      margin-top: 8px;
      margin-bottom: 14px;

      .service-info {
        font-size: 18px;
        font-weight: bold;
        color: #7F694E;
        line-height: 25px;
        margin-top: 8px;
      }

      .service-time {
        font-size: 15px;
        font-weight: 400;
        color: #7F694E;
        line-height: 21px;
      }
    }


    //.close{
    //  margin-top: 34px;
    //  text-align: center;
    //  & > img{
    //    width: 32px;
    //  }
    //}
  }

}
</style>
